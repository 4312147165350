import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GetApiHttp, fetchGetAuthHttp } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import OrganicNumber from "../Components/OrganicNumber";
import tagManagerEvents from "../Utils/GoogleTagManager";
import TagManager from "react-gtm-module";
import { encode as base64_encode } from "base-64";
// import TiktokPixel from "tiktok-pixel";

export default function OrganicNumberScreen(props) {
  const search = useLocation().search;
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidNum, setIsInvalidNum] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  // const [dailyChrages, setdailCharges] = useState("");
  // const [monthlyCharges, setMonthlyCharges] = useState("");
  const [userPackage, setUserPackage] = useState("");
  const utm_source = new URLSearchParams(search).get("utm_source");
  const queryParams = new URLSearchParams(search);
  const perameter = getParameterCaseInsensitive("p");
  const parameter = new URLSearchParams(search).get("parameter")?.toLowerCase();
  const [heCheck, setHECheck] = useState(false);
  const [isModel, setIsModel] = useState(true);
  const navigate = useNavigate();

  // useEffect(() => {
  //   // getpricepoints();
  //   if (window.location.protocol === "https:") {
  //     let url = window.location.href;
  //     url = url.replace("https", "http");
  //     window.location.replace(url);
  //   }
  // }, []);

  useEffect(() => {
    console.log("perameter", parameter);

    if (props.token) {
      if (props.isLanding) {
        const tagManagerArgs = {
          gtmId: "GTM-WSBVX5P",
        };
        TagManager.initialize(tagManagerArgs);

        // TiktokPixel.init("CG9EKK3C77U7T197N78G");
      }
      getAuthFlow();
    }
  }, [props.token]);

  // const getpricepoints = async () => {
  //   try {
  //     let response = await GetApiHttp(ApiNames.getRates);
  //     if (response.status === 200) {
  //       setdailCharges(response.response.Prepaid);
  //       setMonthlyCharges(response.response.Postpaid);
  //     } else {
  //       setdailCharges("5.44Tax/Day");
  //       setMonthlyCharges("29.88Tax/Day");
  //     }
  //   } catch (error) {
  //     console.warn("error", error);
  //   }
  // };
  const getAuthFlow = async () => {
    try {
      let response = await fetchGetAuthHttp(
        ApiNames.getFlow + "?utm_source=" + utm_source,
        props.token
      );
      if (response.status === 200) {
        headerEnrichment(response.response);
      } else {
        alert("Something went wrong.");
      }
    } catch (err) {
      console.log("Error :" + err);
    }
  };

  const headerEnrichment = (response) => {
    // let response = {
    //   isSpecialFlow: false,
    //   fastFlow: false,
    //   msisdn: "3045602206",
    //   otp: "",
    //   milliseconds: 0,
    //   signIn: false,
    //   package: "",
    // };

    try {
      if (response.isSpecialFlow === true) {
        if (response.msisdn !== null && response.otp !== "") {
          navigate("/pin", {
            state: {
              msisdn: response.msisdn,
              otp: response.otp,
              milliseconds: response.milliseconds,
              isFastFlow: response.fastFlow,
              isSpecialFlow: response.isSpecialFlow,
              utm_source: utm_source,
              userPackage: response.package,
              heCheck: heCheck,
              parameter: parameter,
              // token:props.token
            },
          });
        } else {
        }
        // UTM + Comapaign User
      } else {
        if (response.msisdn !== null) {
          if (perameter === "1") {
            setTimeout(() => {
              setIsModel(false);
            }, 2000);
          }
          setMobileNumber(response.msisdn);
          setUserPackage(response.package);
          setHECheck(true);
          // data user
          tagManagerEvents("heUser", utm_source);
          if (response.signIn) {
            fetchLogginFlow(response.msisdn);
          }
        } else {
          // wifi user
          tagManagerEvents("wifiUser", utm_source);
        }
      }
    } catch (error) {
      console.log("headerEnrcihment Error", error);
    }
  };

  const fetchLogginFlow = async (_msisdn) => {
    try {
      setIsLoading(true);
      let response = await fetchGetAuthHttp(
        ApiNames.fetchLoginApi + "?msisdn=" + _msisdn,
        props.token
      );
      console.log("resposnse login==>", response);
      if (response.status === 200) {
        if (response.response.code === 0) {
          tagManagerEvents("login", utm_source);
          let url = window.location.origin;
          let encodedMsisdn = base64_encode(_msisdn);

          url = `${url}/home?ndsism=${encodedMsisdn}`;
          if (url.includes("http:")) {
            url = url.replace("http", "https");
          }
          window.location.replace(url);
          setIsLoading(false);
        }
      } else {
        console.log("404 Status");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("fetchLogginFlow error", error);
      setIsLoading(false);
    }
  };

  const onTextChange = (value) => {
    if (value === "0") {
      setMobileNumber("");
    } else {
      let numRegex = new RegExp("^[0-9]*$");
      if (numRegex.test(value)) {
        setMobileNumber(value);
      }
    }
  };

  const onSendOTP = () => {
    if (mobileNumber.length === 10) {
      if (userPackage) {
        setIsLoading(true);
        checkUserSubscription(userPackage);
      } else {
        checkOperator();
      }
    } else {
      alert("Please Enter a Valid 10 digits number");
    }
  };

  const checkOperator = async () => {
    try {
      setIsLoading(true);
      let response = await GetApiHttp(
        ApiNames.checkOperator + "?msisdn=" + mobileNumber
      );
      if (
        response.status === 200 &&
        response.response.operator.toLowerCase() === "other"
      ) {
        setIsInvalidNum(true);
        setIsLoading(false);
      } else {
        setIsInvalidNum(false);
        checkUserSubscription(response.response.package);
      }
    } catch (error) {
      console.log("checkOperator error", error);
      setIsLoading(false);
    }
  };

  const checkUserSubscription = async (_userPackage) => {
    try {
      let response = await GetApiHttp(
        ApiNames.CheckAlreadySubscribed + "?msisdn=" + mobileNumber
      );
      if (response.status === 200) {
        if (
          response.response.desc.toLowerCase() === "active" &&
          response.response.code === "0"
        ) {
          fetchSendOtp(_userPackage, true);
        } else {
          fetchSendOtp(_userPackage, false);
        }
      } else {
        fetchSendOtp(_userPackage, false);
      }
    } catch (error) {
      fetchSendOtp(_userPackage, false);
    }
  };

  const fetchSendOtp = async (_userPackage, isUserActive) => {
    try {
      let endPoint = isUserActive ? ApiNames.loginSendOtp : ApiNames.sendOtp;
      let response = await fetchGetAuthHttp(
        endPoint + "?msisdn=" + mobileNumber,
        props.token
      );
      if (response.status === 200) {
        if (response.response.result === 0) {
          navigate("/pin", {
            state: {
              msisdn: mobileNumber,
              utm_source: utm_source,
              userPackage: _userPackage,
              isUserActive: isUserActive,
              heCheck: heCheck,
              otp: response.response.desc,
              isSpecialFlow: false,
              parameter: parameter,
            },
          });
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log("fetchSendOtp error", error);
      alert("Unable to send OTP, Please try again");
      setIsLoading(false);
    }
  };

  function getParameterCaseInsensitive(name) {
    for (const [key, value] of queryParams.entries()) {
      if (key.toLowerCase() === name.toLowerCase()) {
        return value;
      }
    }
    return null; // Return null if parameter is not found
  }
  const onHandleJoinNow = () => {
    setIsModel(false);
  };

  return (
    <OrganicNumber
      mobileNumber={mobileNumber}
      isInvalidNum={isInvalidNum}
      parameter={parameter}
      // dailyChrages={dailyChrages}
      // monthlyCharges={monthlyCharges}
      isLoading={isLoading}
      onSendOTP={onSendOTP}
      onTextChange={onTextChange}
      // isModel={isModel}
      isModel={false}
      onHandleJoinNow={onHandleJoinNow}
    />
  );
}
