import React from "react";
import "../../Stylesheets/CustomButtonsStyles.css";

const CustomLoadingPopup = (props) => {
  return (
    <>
      <div className="loadingio-spinner-double-ring-h48sqfrhr8">
        <div className="ldio-1vn4azyhm2x">
          <div></div>
          <div></div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomLoadingPopup;
