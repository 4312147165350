const ApiNames = {
  checkOperator: "subscriber/checkOp",
  sendOtp: "subscriber/sendWebOTP",
  verifyOTP: "subscriber/verifyWebOTP",
  loginSendOtp: "login/sendWebOTP",
  loginVerifyOtp: "login/verifyWebOTP",
  CheckAlreadySubscribed: "checkUser",
  fetchLoginApi: "v2/loginWeb",
  fetchSubscribeApi: "v2/subscribeWeb",
  duaVideos: "videos/category/2",
  bayanVideos: "videos/category/5",
  checkNamazTimings: "prayer",
  inspiration: "greetings",
  islamicNames: "v2/islamic/names",
  allIslamicNames: "islamic/names",
  boyIslamicNames: "islamic/names/gender/1",
  girlIslamicNames: "islamic/names/gender/2",
  searchNames: "search?type=name",
  videoByCategoryId: "videos/category/",
  unsubscribe: "unsubscribeWeb",
  searchSurah: "surah/search",
  searchParah: "para/search",
  searchVideo: "search?type=video&keyword=",
  checkQuizQuestions: "v2/quiz/questions",
  postQuestionQuiz: "v2/quiz/questions/response",
  leaderBoard: "v2/leaderBoard",
  searchQuran: "search?type=quran&keyword=",
  generateToken: "accessWeb",
  getFlow: "getFlow",
  awarenessFlow: "awareness",
  getTopSlider: "slider",
  getRates: "rates",
  isFastFlow: "v2/ffSubscribeWeb",
  HajjApi: "v2/hajj?category=104",
  DuaApi: "v2/emotion",
  utm: "utms",
};
export default ApiNames;
