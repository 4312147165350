import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomLoadingPopup from "../Components/Common/CustomLoadingPopup";
import top_logo from "../Assets/AuthenticationScreen/top_logo.webp";
import mid_banner from "../Assets/AuthenticationScreen/mid_banner.webp";
import bg_main from "../Assets/AuthenticationScreen/bg.webp";
import bg_mobile from "../Assets/AuthenticationScreen/bgmobile.webp";
import { BsClockHistory } from "react-icons/bs";
import "../Stylesheets/OrganicOtp.css";
import boyImg from "../Assets/AuthenticationScreen/naat-competition.webp";
export default function OrganicOtp(props) {
  let textRef1 = useRef();
  let textRef2 = useRef();
  let textRef3 = useRef();
  let textRef4 = useRef();
  let subscribeButtonRef = useRef();
  const HandleBackspace = (index) => {
    props.setotp(index);
  };

  return (
    <>
      <div>
        <div className="bg-image">
          <img className="bg" src={bg_main} alt="logo" />
        </div>
        <div className="md-image">
          <img className="bg" src={bg_mobile} alt="logo" />
        </div>
        <Container>
          <Row>
            <Col>
              <div className="main-content-otp">
                <div className="images">
                  <img
                    className="logo"
                    fetchpriority="high"
                    width="137"
                    height="73"
                    src={top_logo}
                    alt="logo"
                  />
                  <img
                    width="375"
                    height="390"
                    src={props?.parameter === "naatcomp" ? boyImg : mid_banner}
                    alt="logo"
                  />
                </div>

                <div className="landing-subscription-form-otp">
                  <Container>
                    <Row>
                      <Col>
                        <div className="enter-otp">Enter OTP</div>
                        <div className="number_taken_inputcol otp_input">
                          <input
                            className="otp-shadow"
                            ref={textRef1}
                            type="tel"
                            value={props.otp1}
                            maxLength="1"
                            size="1"
                            min="0"
                            max="9"
                            pattern="[0-9]{1}"
                            onChange={(value) => {
                              props.onOTPChange(value.target.value, 0);
                              if (value.target.value.length > 0)
                                textRef2.current.focus();
                            }}
                            onKeyDown={(e) => {
                              {
                                if (props.otp1.length !== 0) {
                                  HandleBackspace(0);
                                } else if (props.otp1.length === 0) {
                                  if (e.keyCode === 8) {
                                    textRef1.current.focus();
                                  }
                                }
                              }
                            }}
                          />
                          <input
                            className="otp-shadow"
                            ref={textRef2}
                            type="tel"
                            value={props.otp2}
                            maxLength="1"
                            size="1"
                            min="0"
                            max="9"
                            pattern="[0-9]{1}"
                            onChange={(value) => {
                              props.onOTPChange(value.target.value, 1);
                              if (value.target.value.length > 0)
                                textRef3.current.focus();
                            }}
                            onKeyDown={(e) => {
                              {
                                if (props.otp2.length !== 0) {
                                  HandleBackspace(1);
                                } else if (props.otp2.length === 0) {
                                  if (e.keyCode === 8) {
                                    textRef1.current.focus();
                                  }
                                }
                              }
                            }}
                          />
                          <input
                            className="otp-shadow"
                            ref={textRef3}
                            type="tel"
                            maxLength="1"
                            size="1"
                            min="0"
                            max="9"
                            pattern="[0-9]{1}"
                            value={props.otp3}
                            onChange={(value) => {
                              props.onOTPChange(value.target.value, 2);
                              if (value.target.value.length > 0)
                                textRef4.current.focus();
                            }}
                            onKeyDown={(e) => {
                              {
                                if (props.otp3.length !== 0) {
                                  HandleBackspace(2);
                                } else if (props.otp3.length === 0) {
                                  if (e.keyCode === 8) {
                                    textRef2.current.focus();
                                  }
                                }
                              }
                            }}
                          />
                          <input
                            className="otp-shadow"
                            ref={textRef4}
                            type="tel"
                            maxLength="1"
                            size="1"
                            min="0"
                            max="9"
                            pattern="[0-9]{1}"
                            value={props.otp4}
                            onChange={(value) => {
                              props.onOTPChange(value.target.value, 3);
                              if (value.target.value.length > 0)
                                subscribeButtonRef.current.focus();
                            }}
                            onKeyDown={(e) => {
                              {
                                if (props.otp4.length !== 0) {
                                  // props.setotp(3);
                                  HandleBackspace(3);
                                } else if (props.otp4.length === 0) {
                                  if (e.keyCode === 8) {
                                    textRef3.current.focus();
                                  }
                                }
                              }
                            }}
                          />
                        </div>
                        {props.isInvalidOTP && (
                          <div className="otp_instructions_red">
                            OTP is invalid or expired
                          </div>
                        )}

                        {props.isLoading ? (
                          <div className="loading-container">
                            <CustomLoadingPopup />
                          </div>
                        ) : (
                          <button
                            ref={subscribeButtonRef}
                            className="submit-btn"
                            onClick={props.onSubscribePressed}
                          >
                            Confirm
                          </button>
                        )}

                        <p>
                          <strong>
                            <span className="otp_instructions">
                              Didn’t Receive PIN?
                            </span>
                            <span className="resend_otp">
                              {" "}
                              {props.timer > 0 ? (
                                <span
                                  style={{
                                    marginLeft: "20px",
                                    fontSize: "0.8rem",
                                    cursor: "default",
                                  }}
                                >
                                  {" "}
                                  <BsClockHistory size="14px" /> 00:
                                  {props.timer}
                                </span>
                              ) : (
                                <span
                                  onClick={() => {
                                    props.handleResendOTP();
                                    props.onResendOtpPressed();
                                  }}
                                >
                                  Resend OTP
                                </span>
                              )}
                            </span>
                          </strong>
                        </p>

                        {/* {props.dailyChrages && props.monthlyCharges && (
                          <p className="bottom-charges-text-container">
                            <span>* </span>
                            Only for Rs. {props.dailyChrages} (Prepaid) &amp;
                            Rs. {props.monthlyCharges} (Postpaid) <span>*</span>
                          </p>
                        )} */}
                        <p className="bottom-charges-text-container">
                          <span>* </span>
                          Only for Rs. 5.44+Tax/Day (Prepaid) & Rs.
                          29.88+Tax/Month (Postpaid) <span>*</span>
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
