import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { Route, Routes } from "react-router-dom";
import ApiNames from "./Constants/ApiNames";
import { generateTokenPost } from "./Utils/FetchApi";
import OrganicNumberScreen from "./Screens/OrganicNumberScreen";
import OrganicOtpScreen from "./Screens/OrganicOtpScreen";
export default function Temp() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    generateToken();
  }, []);

  const generateToken = async () => {
    try {
      let response = await generateTokenPost(ApiNames.generateToken);
      if (response.status === 200) {
        setToken(response.access_token);
      } else {
        console.log("Authentication failed. Please try again.");
      }
    } catch (error) {
      console.warn("token authentication failed", error);
    }
  };
  return (
    <div className="App">
      <Routes>
        <Route
          path="/landing"
          element={<OrganicNumberScreen token={token} isLanding={true} />}
        />
        <Route exact path="/pin" element={<OrganicOtpScreen token={token} />} />
      </Routes>
    </div>
  );
}
