import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomLoadingPopup from "../Components/Common/CustomLoadingPopup";
import top_logo from "../Assets/AuthenticationScreen/top_logo.webp";
import mid_banner from "../Assets/AuthenticationScreen/mid_banner.webp";
import "../Stylesheets/OrganicNum.css";
import boyImg from "../Assets/AuthenticationScreen/naat-competition.webp";

let bg_main = null;

export default function OrganicNumber(props) {
  const [lazyRefresh, setLazyRefresh] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 768) {
      bg_main = require("../Assets/AuthenticationScreen/bg.webp");
      setLazyRefresh(true);
    }
  }, []);

  return (
    <div>
      {/* <div className="bg-image">
        <img
          className="bg"
          width="883px"
          height="326px"
          src={bg_main}
          alt="logo"
        />
      </div>
      <div className="md-image">
        <img
          className="bg"
          width="708px"
          height="520px"
          src={bg_mobile}
          alt="logo"
        />
      </div> */}
      {props.isModel ? (
        <div className="lp-content">
          <div className="c-div">
            <img
              className="logo"
              fetchpriority="high"
              width="137"
              height="73"
              src={top_logo}
              alt="logo"
            />
            <div className="title">
              {" "}
              Enrich Your<span> Spiritual Journey</span> and{" "}
              <span>Earn Rewards</span> with <span>Jazz Islam World!</span>
            </div>
            <button onClick={() => props.onHandleJoinNow()}>Join Now</button>
          </div>
        </div>
      ) : (
        <Container>
          <Row>
            <Col>
              <div className="main-content">
                <div className="images">
                  <img
                    className="logo"
                    fetchpriority="high"
                    width="137px"
                    height="73px"
                    src={top_logo}
                    alt="logo"
                  />
                  <img
                    width="355px"
                    height="363px"
                    src={props?.parameter === "naatcomp" ? boyImg : mid_banner}
                    alt="logo"
                    fetchpriority="high"
                    loading="eager"
                  />
                </div>

                <div className="landing-subscription-form">
                  <Container>
                    <Row>
                      <Col>
                        <div className="enter-num-text">
                          Enter mobile number to receive PIN
                        </div>
                        {/* <form> */}
                        <div className="number-formate-bg b-shadow">+92</div>
                        <div className="number-taken-input-col">
                          <input
                            className="b-shadow"
                            type="tel"
                            placeholder="3xx xxxx xxx"
                            value={props.mobileNumber}
                            maxLength={10}
                            onChange={(val) =>
                              props.onTextChange(val.target.value)
                            }
                          />
                        </div>

                        {props.isInvalidNum && (
                          <div className="num_instructions_red">
                            Service is only available for Jazz customers
                          </div>
                        )}

                        {props.isLoading ? (
                          <div className="loading-container">
                            <CustomLoadingPopup />
                          </div>
                        ) : (
                          <button
                            onClick={props.onSendOTP}
                            className="submit-btn"
                          >
                            Get PIN
                          </button>
                        )}
                        {/* {props.dailyChrages && props.monthlyCharges && (
                        <p className="bottom-charges-text-container">
                          <span>* </span>
                          Only for Rs. {props.dailyChrages} (Prepaid) &amp; Rs.{" "}
                          {props.monthlyCharges} (Postpaid) <span>*</span>
                        </p>
                      )} */}
                        <p className="bottom-charges-text-container">
                          <span>* </span>
                          Only for Rs. 5.44+Tax/Day (Prepaid) & Rs.
                          29.88+Tax/Month (Postpaid)
                          <span>*</span>
                        </p>
                        <div style={{ marginBottom: "10px" }}>
                          <a
                            className="linkSpans"
                            href="/privacypolicy"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                          <span className="linkSpanDot"></span>
                          <a
                            className="linkSpans"
                            href="/terms"
                            target="_blank"
                          >
                            Terms & Conditions
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}
