import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { encode as base64_encode } from "base-64";
import { GetApiHttp, fetchGetAuthHttp } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import OrganicOtp from "../Components/OrganicOtp";
import tagManagerEvents from "../Utils/GoogleTagManager";

export default function OrganicOtpScreen(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(30);
  // const [state.msisdn, setMobileNumber] = useState("");
  const [otp1, setOtp1] = useState([]);
  const [otp2, setOtp2] = useState([]);
  const [otp3, setOtp3] = useState([]);
  const [otp4, setOtp4] = useState([]);

  const [token, setToken] = useState(props.token);
  // const [dailyChrages, setdailCharges] = useState("");
  // const [monthlyCharges, setMonthlyCharges] = useState("");
  const [isInvalidOTP, setIsInvalidOTP] = useState(false);
  const { state } = useLocation();
  const [subUtm, setSubUtm] = useState(state.utm_source);
  let adNetwork = "";

  useEffect(() => {
    // getpricepoints();
    if (state.heCheck === true) {
      setOtp1(state.otp[0]);
      setOtp2(state.otp[1]);
      setOtp3(state.otp[2]);
      setOtp4(state.otp[3]);
    }
    if (props.token) {
      if (state !== null) {
        setToken(props.token);
        checkUtm();
        if (state.isSpecialFlow && state.msisdn && state.otp) {
          setIsLoading(true);
          onSpecialFlowTrue(state.msisdn, state.otp, state.milliseconds);
        }
      } else {
        window.location.replace(window.location.origin + "/phone");
        // navigate("/phone");
      }
    } else {
      console.log("token not availabe");
      window.location.replace(window.location.origin + "/phone");
      // navigate("/phone");
    }
  }, [state, props.token]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearTimeout(interval);
  }, [timer]);

  const checkUtm = async () => {
    try {
      let response = await fetchGetAuthHttp(ApiNames.utm, props.token);

      if (response.status === 200) {
        {
          response.response.map((e) => {
            if (state.utm_source === e.sf_utm) {
              // setSubUtm("econceptions");
              // setAdNetwork(e.ad_network);
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  // const getpricepoints = async () => {
  //   try {
  //     let response = await GetApiHttp(ApiNames.getRates);
  //     if (response.status === 200) {
  //       setdailCharges(response.response.Prepaid);
  //       setMonthlyCharges(response.response.Postpaid);
  //     } else {
  //       setdailCharges("5.44Tax/Day");
  //       setMonthlyCharges("29.88Tax/Day");
  //     }
  //   } catch (error) {
  //     console.warn("error", error);
  //   }
  // };

  const onSpecialFlowTrue = (Number, OTP, Time) => {
    if (Number !== null && OTP !== "") {
      setOtp1(OTP[0]);
      setOtp2(OTP[1]);
      setOtp3(OTP[2]);
      setOtp4(OTP[3]);
      callAutoSubscribe(Time);
    }
  };

  const callAutoSubscribe = (time) => {
    setTimeout(() => {
      fetchSubscribeFlow();
      // -----> subscribeFunctionCall
    }, time);
  };

  const onOTPChange = (value, index) => {
    let numRegex = new RegExp("^[0-9]*$");
    if (numRegex.test(value)) {
      if (index === 0) {
        setOtp1(value);
      } else if (index === 1) {
        setOtp2(value);
      } else if (index === 2) {
        setOtp3(value);
      } else if (index === 3) {
        setOtp4(value);
      }
    }
  };

  const onSubscribePressed = () => {
    setIsLoading(true);
    let otp = otp1 + otp2 + otp3 + otp4;
    if (otp.length === 4) {
      verifyOTP(otp);
    } else {
      alert("Please enter 4 digits OTP");
      setIsLoading(false);
    }
  };

  const fetchSendOtp = async () => {
    try {
      let endPoint = state.isUserActive
        ? ApiNames.loginSendOtp
        : ApiNames.sendOtp;
      let response = await fetchGetAuthHttp(
        endPoint + "?msisdn=" + state.msisdn,
        token
      );
      if (response.status === 200) {
        if (response.response.result === 0) {
          setIsLoading(false);
        }
      } else {
        console.log("404 send otp");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("fetchSendOtp error", error);
      alert("Unable to send OTP, Please try again");
      setIsLoading(false);
    }
  };

  const verifyOTP = async (otp) => {
    try {
      let endPoint = state.isUserActive
        ? ApiNames.loginVerifyOtp
        : ApiNames.verifyOTP;
      let response = await fetchGetAuthHttp(
        endPoint + "?msisdn=" + state.msisdn + "&otp=" + otp,
        token
      );
      console.log("resposnse Verify==>", response);
      if (response.status === 200) {
        if (
          response.response.result === 0 &&
          response.response.desc.toLowerCase() === "valid"
        ) {
          tagManagerEvents("otpVerified", state.utm_source);
          // checkIsAlreadySubscribedNormal();
          setIsInvalidOTP(false);
          if (state.isUserActive) {
            fetchLogginFlow();
          } else {
            fetchSubscribeFlow();
          }
        } else {
          tagManagerEvents("otpFailed", state.utm_source);
          setIsInvalidOTP(true);
          setIsLoading(false);
        }
      } else {
        tagManagerEvents("otpFailed", state.utm_source);
        setIsInvalidOTP(true);
        setIsLoading(false);
      }
    } catch (error) {
      tagManagerEvents("otpFailed", state.utm_source);
      console.log("verifyOTP error", error);
      setIsLoading(false);
    }
  };

  const fetchLogginFlow = async () => {
    try {
      setIsLoading(true);
      let response = await fetchGetAuthHttp(
        ApiNames.fetchLoginApi + "?msisdn=" + state.msisdn,
        token
      );
      if (response.status === 200) {
        if (response.response.code === 0) {
          tagManagerEvents("login", state.utm_source);
          let url = window.location.origin;
          let encodedMsisdn = base64_encode(state.msisdn);

          url = `${url}/home?ndsism=${encodedMsisdn}&perameter=${
            state?.parameter ? state?.parameter : ""
          }`;
          if (url.includes("http:")) {
            url = url.replace("http", "https");
          }
          window.location.replace(url);
          setIsLoading(false);
        }
      } else {
        console.log("404 Status");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("fetchLogginFlow error", error);
      setIsLoading(false);
    }
  };

  const fetchSubscribeFlow = async () => {
    try {
      setIsLoading(true);
      let response = await fetchGetAuthHttp(
        state.isSpecialFlow && state.isFastFlow
          ? ApiNames.isFastFlow +
              "?msisdn=" +
              state.msisdn +
              "&utm_source=" +
              state.utm_source +
              "&subPackage=" +
              state?.userPackage
          : ApiNames.fetchSubscribeApi +
              "?msisdn=" +
              state.msisdn +
              "&utm_source=" +
              state.utm_source +
              "&subPackage=" +
              state?.userPackage,
        props.token
      );
      console.log("resposnse Subscribe==>", response);
      if (response.status === 200) {
        if (response.response.result === 2 || response.response.result === 0) {
          if (
            adNetwork.toLowerCase() === "tiktok" ||
            adNetwork.toLowerCase() === "tik tok"
          ) {
            tagManagerEvents("subscribe", "econTikTok");
          } else if (adNetwork.toLowerCase() === "google") {
            tagManagerEvents("subscribe", "econceptions");
          } else {
            tagManagerEvents("subscribe", subUtm);
          }

          let url = window.location.origin;
          let encodedMsisdn = base64_encode(state.msisdn);

          url = `${url}/home?ndsism=${encodedMsisdn}&perameter=${
            state?.parameter ? state?.parameter : ""
          }`;
          if (url.includes("http:")) {
            url = url.replace("http", "https");
          }
          window.location.replace(url);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        alert("failed to subscribe user");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("fetchSubscribeFlow error", error);
      setIsLoading(false);
    }
  };

  const onResendOtpPressed = async () => {
    try {
      setIsInvalidOTP(false);
      tagManagerEvents("resendOTP", state.utm_source);
      setOtp1("");
      setOtp2("");
      setOtp3("");
      setOtp4("");
      fetchSendOtp();
      setIsLoading(true);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const setotp = (index) => {
    if (index === 0) {
      setOtp1("");
    } else if (index === 1) {
      setOtp2("");
    } else if (index === 2) {
      setOtp3("");
    } else if (index === 3) {
      setOtp4("");
    }
  };

  const handleResendOTP = () => {
    setTimer(30);
  };

  return (
    <OrganicOtp
      mobileNumber={state.msisdn}
      isLoading={isLoading}
      // dailyChrages={dailyChrages}
      // monthlyCharges={monthlyCharges}
      parameter={state?.parameter}
      otp1={otp1}
      otp2={otp2}
      otp3={otp3}
      otp4={otp4}
      isInvalidOTP={isInvalidOTP}
      onResendOtpPressed={onResendOtpPressed}
      onSubscribePressed={onSubscribePressed}
      onOTPChange={onOTPChange}
      setotp={setotp}
      timer={timer}
      handleResendOTP={handleResendOTP}
    />
  );
}
